<template>
<div>
  <NavBar/>

  <div class="flex flex-col">
    <div>
      <p class="text-center text-4xl p-10 text-base-content">Cow Health Tracker</p>
    </div>

    <div v-if="!success" class="flex flex-col">
      <div class="mx-auto p-2">
        <p class="text-center text-base-content">Cow No.</p>
        <input v-model="cowNum" type="tel" placeholder="Cow No." class="input input-bordered w-full  text-base-content">
      </div>

      <div class="mx-auto p-2">
        <p class="text-center text-base-content">Herd</p>
        <input v-model="herd" type="" placeholder="Herd No." class="input input-bordered w-full  text-base-content">
      </div>

      <div class="mx-auto p-2">
        <p class="text-center text-base-content">Alert/Comment</p>
        <input v-model="comment" type="text" placeholder="Alert/Comment" class="input input-bordered w-full  text-base-content">
      </div>

      <p class="text-2xl text-base-content p-4">Treatment Given:</p>

      <div class="text-base-content w-48 mx-auto">  
      

        <div class="form-control">
            <label class="label cursor-pointer">
                <span class="label-text">Drench</span> 
                <input v-model="drench" type="checkbox" class="checkbox checkbox-lg">
            </label>
        </div>

        <!-- <div class="form-control">
            <label class="label cursor-pointer">
                <span class="label-text">B12 Inj</span> 
                <input v-model="b12" type="checkbox" class="checkbox checkbox-lg">
            </label>
        </div> -->

        <div class="form-control">
            <label class="label cursor-pointer">
                <span class="label-text">Glucalphos</span> 
                <input v-model="brown" type="checkbox" class="checkbox checkbox-lg">
            </label>
        </div>

        <div class="form-control">
            <label class="label cursor-pointer">
                <span class="label-text">Cal Pro 375</span> 
                <input v-model="pink" type="checkbox" class="checkbox checkbox-lg">
            </label>
        </div>
        
         <div class="form-control">
            <label class="label cursor-pointer">
                <span class="label-text">Calcium Bolus</span> 
                <input v-model="bolus" type="checkbox" class="checkbox checkbox-lg">
            </label>
        </div>

        <!-- <div class="form-control">
            <label class="label cursor-pointer">
                <span class="label-text">Ketomax</span> 
                <input v-model="keto" type="checkbox" class="checkbox checkbox-lg">
            </label>
        </div> -->

        <!-- <div class="form-control">
            <label class="label cursor-pointer">
                <span class="label-text">Metacam</span> 
                <input v-model="meta" type="checkbox" class="checkbox checkbox-lg">
            </label>
        </div> -->

        <!-- <div class="form-control">
            <label class="label cursor-pointer">
                <span class="label-text">Engermycin</span> 
                <input v-model="engermycin" type="checkbox" class="checkbox checkbox-lg">
            </label>
        </div> -->

        <!-- <div class="form-control">
            <label class="label cursor-pointer">
                <span class="label-text">Intracillin</span> 
                <input v-model="intracillin" type="checkbox" class="checkbox checkbox-lg">
            </label>
        </div> -->

        <!-- <div class="form-control">
            <label class="label cursor-pointer">
                <span class="label-text">Penclox</span> 
                <input v-model="penclox" type="checkbox" class="checkbox checkbox-lg">
            </label>
        </div> -->
      </div>
      <div class="flex justify-center p-6">
        <input @click="submitFuel" type="button" value="Submit" class="btn btn-primary max-w-xs">
      </div>
    </div>

    <div v-if="success" class="flex flex-col">
      <img src="@/assets/greenTick.png" class="w-32 mx-auto p-4">
      <div class="mx-auto p-4">
        <input @click="routeHome" type="button" value="Home" class="btn btn-primary max-w-xs">
      </div>
      <div class="mx-auto p-4">
        <input @click="routeLogout" type="button" value="Logout" class="btn btn-primary max-w-xs">
      </div>
    </div>


    <div v-if="errorFound" class="alert alert-error lg:w-1/3 mx-auto p-4">
      <div class="flex-1">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="w-6 h-6 mx-2 stroke-current">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"></path>
        </svg>
        <label>{{ this.errorMessage }}</label>
      </div>
    </div>
  </div>
</div>



</template>

<script>
import * as firebaseAuth from "firebase/auth";
import NavBar from "@/components/NavBar";

export default {
  name: "cowHealth",
  created() {
    firebaseAuth.getAuth().onAuthStateChanged((user) => {
      if (user) {
        this.userEmail = user.email;
      } else {
        this.user = null;
        this.$router.replace({ name: "login" });
      }
    });
  },
  components: {NavBar},
  data() {
    return {
      cowNum: null,
      herd: null,
      comment: null,
      drench: false,
      b12: false,
      brown: false,
      pink: false,
      bolus: false,
      errorFound: false,
      errorMessage: "",
      success: false,
      userEmail: null
    }
  },
  methods: {
    submitFuel() {
      this.validateFields();

      if (!this.errorFound) {

        let formURL = 'https://docs.google.com/forms/u/3/d/e/1FAIpQLSeYd0JtfNJTGb_bZvY8SStqFaUfeV5hNfdr0mTDsBfCHDPX9g/formResponse?usp=pp_url&entry.587959636=' + this.cowNum + '&entry.1500774741='
            + this.herd + '&entry.1378973227=' + this.comment + '&entry.1995897528=' + this.drench + '&entry.1363154154=' + this.brown
             + '&entry.1489640203=' + this.pink + '&entry.731624688=' + this.bolus +'&submit=Submit';

        fetch(formURL, {
          method: 'GET',
          mode: 'no-cors',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })
        this.success = true;
      }
    },
    validateFields() {
      if (!this.cowNum) {
        this.errorFound = true;
        this.errorMessage = "Please enter a cow number"
      }
      else if (!this.herd) {
        this.errorFound = true;
        this.errorMessage = "Please enter a herd number"
      }
      else if (!this.comment) {
        this.errorFound = true;
        this.errorMessage = "Please enter a comment"
      }
      else if (this.drench) {
        this.errorFound = false;
      }
      else if (this.b12) {
        this.errorFound = false;
      }
      else if (this.brown) {
        this.errorFound = false;
      }
      else if (this.pink) {
        this.errorFound = false;
      }
      else if (this.keto) {
        this.errorFound = false;
      }
      else if (this.meta) {
        this.errorFound = false;
      }
      else if (this.engermycin) {
        this.errorFound = false;
      }
      else if (this.intracillin) {
        this.errorFound = false;
      }
      else if (this.penclox) {
        this.errorFound = false;
      }
      else {
        this.errorFound = true;
        this.errorMessage = "You must select atleast one option"
      }
    },
    routeHome() {
      this.$router.push('/home')
    },
    routeLogout() {
      this.$router.push('/')
    }

  },
}
</script>

<style scoped>

</style>