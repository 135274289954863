<template>
<div>
  <NavBar/>

  <div class="flex flex-col">
    <div>
      <p class="text-center text-4xl p-10 text-base-content">Rothlow Fuel</p>
    </div>

    <div v-if="!success" class="flex flex-col">
      <div class="mx-auto p-2">
        <p class="text-center text-base-content">Vehicle</p>
        <select v-model="vehicle" class="select select-bordered select-primary w-full text-base-content">
          <option disabled="disabled" selected="selected">Choose your vehicle</option>
          <option class=" text-base-content">Honda 4 wheeler</option>
          <option>Gary 4 wheeler</option>
          <option>Sharna 2 wheeler</option>
          <option>Brodie 2 wheeler</option>
          <option>Dakota 2 wheeler</option>
          <option>Vitara</option>
          <option>Other</option>
        </select>
      </div>

      <div v-if="vehicle === 'Other'" class="mx-auto p-2">
        <p class="text-center text-base-content">Other</p>
        <input v-model="otherVehicle" type="text" placeholder="" class="input input-bordered w-full  text-base-content">
      </div>
      <div class="mx-auto p-2">
        <p class="text-center text-base-content">Vehicle Kilometres</p>
        <input v-model="kmsVehicle" type="tel" placeholder="kms" class="input input-bordered w-full  text-base-content">
      </div>

      <div class="mx-auto p-2">
        <p class="text-center text-base-content">Litres on Bowser before</p>
        <input v-model="bowserBefore" type="tel" placeholder="litres before" class="input input-bordered w-full text-base-content">
      </div>

      <div class="mx-auto p-2">
        <p class="text-center text-base-content">Litres on Bowser after</p>
        <input v-model="bowserAfter" type="tel" placeholder="litres after" class="input input-bordered w-full text-base-content">
      </div>

      <div class="flex justify-center p-6">
        <input @click="submitFuel" type="button" value="Submit" class="btn btn-primary max-w-xs">
      </div>
    </div>

    <div v-if="success" class="flex flex-col">
      <img src="@/assets/greenTick.png" class="w-32 mx-auto p-4">
      <div class="mx-auto p-4">
        <input @click="routeHome" type="button" value="Home" class="btn btn-primary max-w-xs">
      </div>
      <div class="mx-auto p-4">
        <input @click="routeLogout" type="button" value="Logout" class="btn btn-primary max-w-xs">
      </div>
    </div>


    <div v-if="errorFound" class="alert alert-error lg:w-1/3 mx-auto p-4">
      <div class="flex-1">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="w-6 h-6 mx-2 stroke-current">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"></path>
        </svg>
        <label>{{ this.errorMessage }}</label>
      </div>
    </div>
  </div>
</div>



</template>

<script>
import * as firebaseAuth from "firebase/auth";
import NavBar from "@/components/NavBar";

export default {
  name: "FuelView",
  created() {
    firebaseAuth.getAuth().onAuthStateChanged((user) => {
      if (user) {
        this.userEmail = user.email;
      } else {
        this.user = null;
        this.$router.replace({ name: "login" });
      }
    });
  },
  components: {NavBar},
  data() {
    return {
      bowserAfter: null,
      bowserBefore: null,
      kmsVehicle: null,
      vehicle: null,
      otherVehicle: null,
      errorFound: false,
      errorMessage: "",
      success: false,
      userEmail: null
    }
  },
  methods: {
    submitFuel() {
      this.validateFields();

      if (this.vehicle === 'Other') {
        this.vehicle = this.otherVehicle;
      }

      if (!this.errorFound) {


        let formURL = 'https://docs.google.com/forms/u/1/d/e/1FAIpQLSdznLbE6wxhhbWu55qiPrxa3UF7W5hmY263v9aAvCwOYMg_lA/formResponse?usp=pp_url&entry.1077630555=' + this.userEmail + '&entry.1671457251='
            + this.vehicle + '&entry.507263664=' + this.kmsVehicle + '&entry.2112293760=' + this.bowserBefore + '&entry.125742779=' + this.bowserAfter + '&submit=Submit';

        fetch(formURL, {
          method: 'GET',
          mode: 'no-cors',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })
        this.success = true;
      }
    },
    validateFields() {
      if (this.bowserAfter <= 0) {
        this.errorFound = true;
        this.errorMessage = "You must enter the bowser reading before you start refueling"
      }
      else if (this.bowserBefore <= 0) {
        this.errorFound = true;
        this.errorMessage = "You must enter the bowser reading after you have refueled"
      }
      else if (this.kmsVehicle <= 0) {
        this.errorFound = true;
        this.errorMessage = "You must enter your vehicles odometer reading"
      }
      else if (this.vehicle === null) {
        this.errorFound = true;
        this.errorMessage = "You must enter a vehicle"
      }
      else if (this.vehicle === 'Other') {
        if (this.otherVehicle === null) {
          this.errorFound = true;
          this.errorMessage = "You must enter a description for Other"
        }
      } else {
        this.errorFound = false;
      }
    },
    routeHome() {
      this.$router.push('/home')
    },
    routeLogout() {
      this.$router.push('/')
    }

  },
}
</script>

<style scoped>

</style>