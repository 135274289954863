<template>
<div>
  <div class="NavBar mb-2 text-neutral-content rounded-box">
    <div class=" flex">
      <button @click="focusNavMenu" class="btn btn-square btn-ghost">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="inline-block w-6 h-6 stroke-current">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
        </svg>
      </button>
    </div>
  </div>
  <transition name="fade">
  <div v-if="navModal" class="absolute" tabindex="1">
    <ul class="menu w-64 border bg-neutral menu-compact lg:menu-normal rounded-box">
      <li @click="routeHome">
        <a>
          <p class="text-neutral-content">
            Home
          </p>
        </a>
      </li>
      <li>
        <a @click="logOut" >
          <p class="text-neutral-content">
            Logout
          </p>
        </a>
      </li>
    </ul>
  </div>
  </transition>
</div>
</template>

<script>
import { getAuth } from "firebase/auth";

export default {
  name: "NavBar",
  data() {
    return {
      navModal: false
    }
  },
  methods: {
    focusNavMenu() {
      this.navModal = !this.navModal;
    },
    logOut() {
      getAuth().signOut().then(() => {
          this.$router.push('/')
          this.navModal = !this.navModal;
      })
    },
    routeHome() {
      this.$router.replace({ name: "Home" });
    }
  },
}
</script>

<style scoped>

</style>