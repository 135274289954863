import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCR6N0eSzlH1jty24wT6qNsIn7_UDkOrlo",
    authDomain: "rothlow-92eb2.firebaseapp.com",
    projectId: "rothlow-92eb2",
    storageBucket: "rothlow-92eb2.appspot.com",
    messagingSenderId: "414078128010",
    appId: "1:414078128010:web:b5f1a10307ce98a3924273"
}

export class FirebaseHelper {
    constructor() {
        firebase.initializeApp(firebaseConfig);
        this.db = firebase.firestore();
    }
}