<template>
  <div class="hero min-h-screen bg-base-200">
    <div class="flex-col justify-center hero-content lg:flex-row">
      <div class="text-center lg:text-left">
        <h1 class="mb-5 text-5xl font-bold text-primary">
          Rothlow Ltd
        </h1>
      </div>
      <div class="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
        <div class="card-body">
          <div class="form-control">
            <label class="label">
              <span class="label-text">Email</span>
            </label>
            <input type="text" placeholder="Email" class="input input-bordered text-base-content" v-model="form.name">
          </div>
          <div class="form-control">
            <label class="label">
              <span class="label-text">Password</span>
            </label>
            <input type="password" placeholder="Password" class="input input-bordered text-base-content" v-model="form.password">
          </div>
          <div class="form-control mt-6">
            <input type="button" value="Login" class="btn btn-primary"
              @click="Login">
          </div>
          <p>{{ this.error }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

export default {
  name: "LoginView",
  data() {
    return {
      form: {
        name: '',
        password: ''
      },
      error: null
    }
  },
  methods: {
    routeHome() {
      this.$router.push('/')
    },
    Login() {
      const auth = getAuth();
      signInWithEmailAndPassword(auth, this.form.name, this.form.password)
          .then(() => {
            // Signed in
            this.$router.replace({ name: "Home" });
            // ...
          })
          .catch((error) => {
            this.error = error.message;
          });
    }
  },
}
</script>

<style scoped>

</style>