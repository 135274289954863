<template>
  <div>
    <NavBar/>

    <div>
      <p class="text-center text-4xl p-10 text-base-content">Home</p>
    </div>
    <div class="flex justify-center ">
      <div class="flex-col max-w-sm w-1/2 p-10 ">
        <div @click="routeFuel" class="card bg-base-200 rounded-box place-items-center mx-auto">
          <img class="h-28" src="../assets/fuel.png">
        </div>
        <div class="divider"></div>
        <div @click="routeHealth" class="card bg-base-200 rounded-box place-items-center mx-auto">
          <img class="h-28" src="../assets/health.png">
        </div>      
      </div>
    </div>
  </div>
</template>

<script>
import * as firebaseAuth from "firebase/auth";
import NavBar from "@/components/NavBar";

export default {
  name: 'HomeView',
  components: {
    NavBar,
  },
  data() {
    return {
      user: null
    }
  },
  created() {
    firebaseAuth.getAuth().onAuthStateChanged((user) => {
      if (user) {
        this.user = user;
      } else {
        this.user = null;
        this.$router.replace({ name: "Login" });
      }
    });
  },
  methods: {
    routeFuel() {
      this.$router.push('/fuel')
    },
    routeHealth() {
      this.$router.push('/cowhealth')
    }
  },
}
</script>
