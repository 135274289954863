import { createRouter, createWebHashHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue'
import LoginView from "@/views/LoginView";
import FuelView from "@/views/FuelView";
import cowHealth from "@/views/cowHealth";

const routes = [
    {
        path: '/home',
        name: 'Home',
        component: HomeView
    },
    {
        path: '/',
        name: 'Login',
        component: LoginView
    },
    {
        path: '/fuel',
        name: 'Fuel',
        component: FuelView
    },
    {
        path: '/cowhealth',
        name: 'cowHealth',
        component: cowHealth
    },
]

const router = new createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
